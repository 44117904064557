.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
}
h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
}
body {
  padding: 0;
  margin: 0;
}
