.project {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../assets/bg.png");
}

.project h1 {
  margin-top: 50px;
  color: #e95f03;
  font-size: 35px;
}

.project img {
  width: 700px;
  border-radius: 10px;
}

.project .tech {
  font-size: 30px;
  color: #e95f03;
  text-align: center;
  width: 90%;
}

.project svg {
  font-size: 60px;
  color: black;
}

#description {
  font-size: 20px;
  color: black;
  width: 50%;
}

/* DESCRIPTION STYLING */

.desc {
  width: 80%;
}

@media only screen and (max-width: 900px) {
  #description {
    font-size: 15px;
    color: black;
    width: 80%;
  }
}