.navbar {
  width: 100%;
  height: 80px;
  background: #02123a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10;
}

.links {
  display: flex;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 0 20px;
  font-size: 18px;
  transition: color 0.3s;
}

.navbar a:hover {
  color: #e95f03;
}

.toggleButton {
  display: none;
  align-items: center;
  justify-content: center;
}

.toggleButton button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  font-size: 30px;
}

#open {
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #02123a;
}

#open .links {
  flex-direction: column;
}

#open .links a {
  margin: 20px 0;
  font-size: 24px;
}

@media only screen and (max-width: 900px) {
  .navbar a {
    margin: 0 10px;
    font-size: 18px;
  }
}