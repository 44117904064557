.carousel-container {
  position: relative;
  width: 80%;
  margin: auto;
  overflow: hidden;
  max-width: 1000px; /* Ensure a max width for the carousel */
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px; /* Set a fixed height for the carousel */
  background-color: black; /* Set the background color to black */
}

.carousel-slide img,
.carousel-slide video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures media contain within the area without stretching */
  border-radius: 10px;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.carousel-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.carousel-indicator {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 2px solid black;
}

.carousel-indicator.active {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid #e95f03;
}