.home {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  margin-bottom: 200px;
}

.about {
  width: 95%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("../assets/bg.png");
  background-color: rgb(247, 250, 251);
  color: #000000;
}

.about h2 {
  font-size: 80px;
  color: #e95f03;
  height: 20%;
}

.about .prompt {
  width: 40%;
  font-size: 30px;
}
.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: rgb(0, 0, 0);
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}

.skills h1 {
  color: #e95f03;
}

.skills .list {
  list-style: none;
  width: 60%;
  margin-block-start: 0px;
  padding-inline-start: 0px;
}

.skills .list span {
  font-size: 20px;
}

/* education section */

.education {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 0;
}

.education h1 {
  color: #e95f03;
}

.education .list {
  list-style: none;
  width: 60%;
  margin-block-start: 0px;
  padding-inline-start: 0px;
}

.education .item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.education .item img {
  margin-right: 20px;
}

.education .item h2 {
  font-size: 25px;
  margin: 0;
  margin-top: 30px;
}

.education .item img {
  margin-right: 20px;
  width: 175px; /* Adjust the size of the logo */
  height: auto;
}

.education .item .details {
  display: flex;
  flex-direction: column;
}

.education .item .details span {
  margin-bottom: 10px; /* Add space between lines */
  font-size: 20px;
}

.education .item .date {
  text-align: right;
  font-size: 20px;
  margin-left: 10%;
  align-self: flex-start;
  white-space: nowrap;
}

.date-mobile {
  display: none;
}

@media only screen and (max-width: 900px) {
  .about h2 {
    font-size: 40px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .skills .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
}


/* education media queries */

@media only screen and (max-width: 900px) {
  .education .item img {
    display: none;
  }

  .education .list {
    width: 80%;
  }

  .education .item {
    flex-direction: column;
    align-items: flex-start;
  }

  .education .item .date {
    display: none;
  }

  .education .item .date-mobile {
    display: block;
    font-size: 20px;
    margin-bottom: 5px;
  }
}